import React, { Component } from 'react'
import { StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slick from 'react-slick'

class Slider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      slides: Object.values(props.slides),
      current: 0,
    }
  }
  componentDidMount() {
    this.interval = setInterval(() => {
      this.setState(state => ({
        current:
          state.current < state.slides.length - 1 ? (state.current += 1) : 0,
      }))
    }, 4000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  render() {
    var settings = {
      dots: false,
      infinite: true,
      speed: 1000,
      arrows: false,
      autoplay: true,
      slidesToShow: 1,
      slidesToScroll: 1,
    }
    return (
      <div className="slider_container">
        <div className="slider_trans_black" />
        <Slick {...settings}>
          {this.state.slides.map((el, index) => (
            <Img
              key={index}
              fadeIn={true}
              className="slider_img"
              fluid={el.childImageSharp.fluid}
            />
          ))}
        </Slick>
      </div>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        sliderOne: file(relativePath: { eq: "slider/slide1.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1700, maxHeight: 800, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        sliderTwo: file(relativePath: { eq: "slider/slide2.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1700, maxHeight: 800, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        sliderThree: file(relativePath: { eq: "slider/slide3.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1700, maxHeight: 800, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        sliderFour: file(relativePath: { eq: "slider/slide4.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1700, maxHeight: 800, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        sliderFive: file(relativePath: { eq: "slider/slide5.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1700, maxHeight: 800, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => <Slider slides={data} />}
  />
)
