import React, { Component } from 'react'
import './countdown.css'
import CountdownJS from './count.min'

import enMessages from '../i18n/en.json'
import plMessages from '../i18n/pl.json'
import itMessages from '../i18n/it.json'

const messages = {
  en: enMessages,
  pl: plMessages,
  it: itMessages,
}

class Countdown extends Component {
  componentDidMount() {
    const el = document.querySelector('#countdown-wrapper')
    if (el) {
      this.cd = new CountdownJS({
        cont: el,
        date: {
          year: 2019,
          month: 4,
          day: 13,
          hour: 16,
        },
        outputTranslation: {
          year: messages[this.props.locale].years,
          day: messages[this.props.locale].days,
          hour: messages[this.props.locale].hours,
          minute: messages[this.props.locale].minutes,
          second: messages[this.props.locale].seconds,
        },
        endCallback: null,
        outputFormat: 'day|hour|minute|second',
      })
      this.cd.start()
    } else {
      this.forceUpdate()
    }
  }

  componentWillUnmount() {
    if (this.cd) {
      this.cd.stop()
    }
  }

  render() {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'center', padding: '35px 0' }}
        id="countdown-wrapper"
      />
    )
  }
}

export default Countdown
