import React from 'react';
import { navigate, Link, graphql } from 'gatsby';
import Img from 'gatsby-image';

import enMessages from '../i18n/en.json';
import plMessages from '../i18n/pl.json';
import itMessages from '../i18n/it.json';
import Layout from '../components/layout';
import Countdown from '../components/countdown';
import Slider from '../components/slider';

import { prependLocale } from '../utils';

import ReactFlagsSelect from 'react-flags-select';
import Footer from '../components/footer';
import 'react-flags-select/css/react-flags-select.css';
import Slick from 'react-slick';

import '../../node_modules/slick-carousel/slick/slick.css';
import '../../node_modules/slick-carousel/slick/slick-theme.css';
import './index.css';

const messages = {
  en: enMessages,
  pl: plMessages,
  it: itMessages,
};

const getFlag = l => (l === 'en' ? 'GB' : l === 'it' ? 'IT' : 'PL');

const onSelectFlag = f =>
  f === 'GB' ? navigate('/') : f === 'IT' ? navigate('/it/') : navigate('/pl/');

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  initialSlide: 0,
  lazyLoad: true,
};

const IndexPage = ({ pageContext: { locale }, data }) => (
  <Layout locale={locale}>
    <div
      style={{
        position: 'absolute',
        top: '12px',
        right: '30px',
        zIndex: 1000,
        background: 'white',
        borderRadius: '7px',
      }}
    >
      <ReactFlagsSelect
        countries={['GB', 'IT', 'PL']}
        customLabels={{ GB: 'EN', PL: 'PL', IT: 'IT' }}
        defaultCountry={getFlag(locale)}
        onSelect={onSelectFlag}
        placeholder=""
      />
    </div>
    <div className="index__desktop">
      <div className="title_container">
        <div className="title_front">
          {messages[locale].getting_married}
        </div>
        <div className="love_birds">
          <Img fixed={data.birds.childImageSharp.fixed} />
        </div>
        <div className="title">
          Irmina <span>&amp;</span> Fabrizio
        </div>
        <div className="title_date">
          <span className="swirl_left">
            <span className="swirl_right">13.04.2019</span>
          </span>
        </div>
        <div className="title_under">{messages[locale].save_date}</div>
      </div>

      <Slider />
    </div>
    <div className="index__mobile">
      <div className="title_container">
        <div className="title_front">
          {messages[locale].getting_married}
        </div>
        <div className="love_birds">
          <Img fixed={data.birds.childImageSharp.fixed} />
        </div>
        <div className="title">
          Irmina <span>&amp;</span> Fabrizio
        </div>
        <div style={{ maxWidth: '800px', margin: '0 auto' }}>
          <Slick {...settings}>
            {data.slides.edges.map((el, index) => (
              <div key={index}>
                <Img
                  imgStyle={{ borderRadius: '8px' }}
                  style={{ margin: '0 12px' }}
                  fluid={el.node.childImageSharp.fluid}
                />
              </div>
            ))}
          </Slick>
        </div>
        <div className="title_date">
          <Img
            imgStyle={{ objectFit: 'contain' }}
            fixed={data.swirl_left_date.childImageSharp.fixed}
          />
          <div>13.04.2019</div>
          <Img
            imgStyle={{ objectFit: 'contain' }}
            fixed={data.swirl_right_date.childImageSharp.fixed}
          />
        </div>
        <div className="title_under">{messages[locale].save_date}</div>
      </div>
    </div>
    <div className="countdown_container">
      <h2 className="centered_title">{messages[locale].countdown_wedding}</h2>
      <div id="defaultCountdown">
        <Countdown locale={locale} date={'2019-04-13'} />
      </div>
      <div className="rsvp_button">
        <Img
          imgStyle={{ objectFit: 'contain' }}
          fixed={data.swirl_left.childImageSharp.fixed}
        />
        <div
          dangerouslySetInnerHTML={{ __html: messages[locale].rsvp }}
          style={{ color: 'white' }}
        />
        <Img
          imgStyle={{ objectFit: 'contain' }}
          fixed={data.swirl_right.childImageSharp.fixed}
        />
      </div>
    </div>

    <div className="about_content">
      <div className="about_topsign">&amp;</div>
      <div className="full_width_centered">
        <div className="left12_first">
          <span className="names_titles">{messages[locale].the_bride}</span>
          <h2>IRMINA</h2>
          <div style={{ margin: '0 auto', textAlign: 'center' }}>
            <Img
              fixed={data.bride.childImageSharp.fixed}
              style={{ borderRadius: '16px' }}
            />
          </div>
        </div>
        <div className="left12_last">
          <span className="names_titles">{messages[locale].the_groom}</span>
          <h2>FABRIZIO</h2>
          <div style={{ margin: '0 auto', textAlign: 'center' }}>
            <Img
              fixed={data.groom.childImageSharp.fixed}
              style={{ borderRadius: '16px' }}
            />
          </div>
        </div>

        <div className="clear" />
      </div>
    </div>

    <div className="home_bottom">
      <div className="full_width_centered">
        <div className="left13">
          <div className="bottom_icon">
            <Img fixed={data.location.childImageSharp.fixed} />
          </div>
          <h2>{messages[locale].wedding_location}</h2>
          <span className="subtitle">
            {messages[locale].wedding_location_sub}
          </span>
          <Link
            to={prependLocale(locale) + '/wedding-location/'}
            className="post_read_more"
          >
            {messages[locale].read_more}
          </Link>
        </div>
        <div className="left13">
          <div className="bottom_icon">
            <Img fixed={data.blog.childImageSharp.fixed} />
          </div>
          <h2>{messages[locale].schedule}</h2>
          <span className="subtitle">{messages[locale].schedule_sub}</span>
          <Link
            to={prependLocale(locale) + '/schedule/'}
            className="post_read_more"
          >
            {messages[locale].read_more}
          </Link>
        </div>
        <div className="left13_last">
          <div className="bottom_icon">
            <Img fixed={data.accommodation.childImageSharp.fixed} />
          </div>
          <h2>{messages[locale].accommodation}</h2>
          <span className="subtitle">{messages[locale].accommodation_sub}</span>
          <Link
            to={prependLocale(locale) + '/accommodation/'}
            className="post_read_more"
          >
            {messages[locale].read_more}
          </Link>
        </div>

        <div className="clear" />
      </div>
    </div>
    <Footer locale={locale} />
  </Layout>
);

export default IndexPage;

export const pageQuery = graphql`
  query {
    accommodation: file(relativePath: { eq: "icons/bed.png" }) {
      childImageSharp {
        fixed(width: 38, height: 38) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    location: file(relativePath: { eq: "icons/marker.png" }) {
      childImageSharp {
        fixed(width: 38, height: 38) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    blog: file(relativePath: { eq: "icons/calendar.png" }) {
      childImageSharp {
        fixed(width: 38, height: 38) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    sliderOne: file(relativePath: { eq: "slider/slide1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1700, maxHeight: 800, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bride: file(relativePath: { eq: "bride.jpg" }) {
      childImageSharp {
        fixed(height: 300, width: 263) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    groom: file(relativePath: { eq: "groom.jpg" }) {
      childImageSharp {
        fixed(height: 300, width: 263, cropFocus: CENTER) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    birds: file(relativePath: { eq: "birds_icon.png" }) {
      childImageSharp {
        fixed(width: 118, height: 61) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    swirl_left: file(relativePath: { eq: "swirl_left_small.png" }) {
      childImageSharp {
        fixed(width: 83, height: 25) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    swirl_right: file(relativePath: { eq: "swirl_right_small.png" }) {
      childImageSharp {
        fixed(width: 83, height: 25) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    swirl_left_date: file(relativePath: { eq: "swirl_left.png" }) {
      childImageSharp {
        fixed(width: 109, height: 42) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    swirl_right_date: file(relativePath: { eq: "swirl_right.png" }) {
      childImageSharp {
        fixed(width: 109, height: 42) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    slides: allFile(sort: { order:ASC, fields: [name] }, filter: { relativePath: { glob: "slider/slide*" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 800, maxHeight: 600, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
